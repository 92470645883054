.homepage-button {
    padding: 10px 50px;
    border-radius: 9999px;
    font-size: 3rem;
    color: var(--secondary-color);
    font-family: 'Roboto',sans-serif;
}
  
.homepage-button:disabled {
    pointer-events: none;
    background-color: #ddd;
    color: #fff;
    font-size: 2rem;
  }