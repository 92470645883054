.lesson-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    background-color: rgba(87, 29, 76, .7);
    z-index: 101;
    display: none;
}

.lesson-modal.lesson-modal--active {
    display: block;
}

.lesson-modal-box {
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 100%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 7px;
    max-height: 98vh;
    width: 450px;
    max-width: 98vw;
}
.lesson-modal__closing-button {
    font-size: 2.4rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--main-color);
    display: block;
    margin-left: auto;
}

.lesson-modal-box__header {
    text-align: center;
    font-weight: 700;
    color: var(--secondary-color);
    font-size: 2.8rem;
    margin: 0 0 20px;
}
.lesson-modal-box__img {
    height: auto;
    width: 150px;
    display: block;
    margin: 30px auto;
}

.lesson-modal-box__button-wrapper {
    display: flex;
    justify-content: space-between;
}

.lesson-modal-box__button {
    margin: 0;
    width: 150px;
    max-width: 50vw;
}

.lesson-modal-box__button--outline {
    background: none;
    color: var(--main-color);
    border: 2px solid var(--main-color);
}



.lesson-modal-box__text {
    width: 80%;
    display: block;
    margin: 10px auto;
    color: var(--main-color);
    text-align: center;
    font-size: 1.6rem;
}

@media (max-width: 600px) {
    .lesson-modal-box {
        width: 98%;;
    }
}