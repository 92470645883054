.main-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh;
    background-color: rgba(87, 29, 76, .7);
    z-index: 101;
    display: none;
}

.main-modal.main-modal--active {
    display: block;
}

.main-modal__closing-div {
    width: 100%;
    height: 100%;

}

.main-modal-box {
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 100%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    background-color: var(--white-color);
    padding: 20px;
    border-radius: 7px;
    max-height: 98vh;
}

.main-modal__closing-button {
    font-size: 2.4rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--main-color);
    display: block;
    margin-left: auto;
}

@media (max-width: 600px) {
    .main-modal-box {
        width: 98%;;
    }
}