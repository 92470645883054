.main-page-mobile {
    min-height: 100vh;
  }
  
  .main-page-mobile__background {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/en.webp");
    background-position: center;
    background-size: cover;
    padding: 70px 5px 40px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .main-page-mobile__background--en {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/en.webp");
  }
  
  .main-page-mobile__background--es {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/es.webp");
  }
  
  .main-page-mobile__background--de {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/de.webp");
  }
  
  .main-page-mobile__background--it {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/it.webp");
  }
  

  .main-page-mobile__background--nl {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/nl.webp");
  }

  .main-page-mobile__background--pl {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/pl.webp");
  }

  .main-page-mobile__background--ru {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/ru.webp");
  }

  .main-page-mobile__background--fr {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/fr.webp");
  }

  .main-page-mobile__background--pt {
    background-image:  linear-gradient(
      rgba(87,29,76, 0.7), 
      rgba(87,29,76, 0.7)
    ),
    /* bottom, image */
    url("../backgroundImages/pt.webp");
  }

  .main-page-mobile__nav-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .main-page-mobile__logo-wrapper {
    padding: 10px 20px;
    background-color: var(--main-color);
    width: fit-content;
  }
  
  .main-page-mobile__logo {
    height: 30px;
    cursor: pointer;
  }
  
  .main-page-mobile__logout-button {
    background-color: var(--main-color);
    border: none;
    color: var(--white-color);
    padding: 10px 20px;
    text-transform: uppercase;
    font-family: 'Roboto';
    letter-spacing: 1px;
    border-radius: 0 0 0 5px;
  }
  
  /* .main-page-mobile__main-wrapper {
    display: block;
  } */

  .main-page-mobile__content-wrapper {
    max-width: 400px;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    padding: 0 5px;
    align-items: center;
    justify-content: center;
  }


  .main-page-mobile__content-wrapper:first-of-type {
    margin: 0;
  }

  .main-page-mobile__content-wrapper:last-of-type {
    align-items: center;
  }

  .main-page__main-speech-bubble {
    margin-left: auto;
  }
  
    .main-page-mobile__speech-bubble-main-header {
      font-weight: 700;
      font-family: 'Roboto';
      font-size: 4rem;
      font-weight: 400;
      color: var(--main-color);
    }
  
    .main-page-mobile__speech-bubble-header {
      font-family: 'roboto';
      font-size: 3.5rem;
      font-weight: 400;
      color: var(--secondary-color);
    }
  
    .main-page-mobile__text {
      color: var(--main-color);
      margin: 5px 0;
      line-height: 130%;
    }
  
    .main-page-mobile__content-div {
      background-color: #fff;
      color: var(--main-color);
      border-radius: 7px;
      padding: 10px;
      width: 100%;
      max-width: 500px;
    }
  
    .main-page-mobile__content-title {
      display: block;
      color: var(--main-color);
      font-family: 'roboto';
      font-size: 2.6rem;
      padding: 10px 30px;
      text-align: center;
    }
  
    .main-page-mobile__inner-content-wrapper {
      padding: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }
  
    .main-page-mobile__inner-content-wrapper > * {
      text-align: center;
      color: var(--main-color);
      font-weight: 600;
      font-size: 1.3rem;
    }
  
    .main-page-mobile__activity-data-div {
      margin-bottom: 10px;
    }
  
    .main-page-mobile__activity-data {
      font-size: 3rem;
      font-family: 'roboto';
      color: var(--secondary-color);
    }
  
    .main-page-mobile__levels-wrapper {
      text-align: center;
      margin-bottom: 30px;
      width: 100%;
      max-width: 500px;
    }
  
    .main-page-mobile__level-buttons {
      margin: 20px 0 10px;
      display: flex;
    justify-content: space-around;
    }
  
    .main-page-mobile__level-button-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
    }
  
    .main-page-mobile__level-button-img{
      width: 60px;
      margin-bottom: 5px;
    }
  
    .main-page-mobile__level-button-img--active {
      width: 80px;
  
    }
  
    .main-page-mobile__level-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      margin: 0 5px;
      color: #fff;
      font-weight: 700;
    }
  
    .main-page-mobile__level-button--active  {
      font-size: 2.2rem;
    }
    .main-page-mobile__progress-bar {
      width: 90%;
      margin: 5px auto;
    }
    .main-page-mobile__progress-bar-desc {
      color: #fff;
      margin: 5px 0;
    }
  
  .main-page-mobile__lessons-wrapper {
    width: 100%;
    max-width: 350px;
    align-self: center;  
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
  
    .main-page-mobile__big-square-button {
      border: none;
      border-radius: 7px;
      background-color: var(--secondary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 10px;
      color: var(--main-color);
      font-weight: 700;
      font-size: 2.3rem;
      text-decoration: none;
      min-height: 100%;

    }
  
    .main-page-mobile__transparent-button {
      background-color: transparent;
      border: 2px solid #fff;
      border-radius: 5px;
      align-self: center;
      text-align: center;
      padding: 10px;
      color: #fff;
      font-size: 2.3rem;
      font-weight: 700;
      width: 100%;
      text-decoration: none;
      display: block;
      min-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .main-page-mobile__content-div--your-activity {
        background-color: var(--main-color);
        margin: 0 auto;
    }

    .main-page-mobile__content-title--your-activity {
        color: #fff;
        padding: 0;
    }
  
    .main-page-mobile__content-div--active-course {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .main-page-mobile__content-title--active-course {
        padding: 0;
    }
  
    .main-page-mobile__courses-list {
      list-style: none;
      margin: 10px;
  
    }
  
    .main-page-mobile__courses-list-element {
      color: var(--main-color);
      font-size: 2.6rem;
      padding: 5px 0;
      display: flex;
      align-items: center;
    }
  
    .main-page-mobile__course-flag {
      width: 50px;
      margin-right: 5px;
      border-radius: 50%;
      -webkit-box-shadow: 0px 0px 6px -4px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 6px -4px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 6px -4px rgba(66, 68, 90, 1);
    }
  
    .main-page-mobile__show-more-button {
      display: block;
      margin: 0 auto;
      background-color: transparent;
      border: none;
      color: var(--main-color);
      cursor: pointer;
    }
  
.main-page__fun-facts-widget {
    margin: 20px 0 50px;
}

  .fun-facts-widget__title {
    font-family: 'roboto';
    color: var(--secondary-color);
    font-size: 3rem;
  }
  
  .fun-facts-widget__content-wrapper {
    display: flex;
    align-items: center;
  }
  
  .fun-facts-widget__img {
    width: 80px;
    margin-right: 10px;
  }
  
  .fun-facts-widget__text {
    font-size: 1.4rem;
    color: var(--main-color);
    text-align: left;
  }
  
    .main-page-mobile__courses-modal-content {
      text-align: center;
    }
  
    .main-page-mobile__courses-modal-header {
      font-family: 'roboto';
      font-size: 2rem;
    }
  
    .main-page-mobile__courses-modal-your-courses-list, .main-page-mobile__courses-modal-other-courses-list {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 10px 0 20px;
    }
  
    .main-page-mobile__courses-modal-your-courses-list-element, .main-page-mobile__courses-modal-other-courses-list-element {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin:  10px;
    }
  
    .main-page-mobile__courses-modal-your-courses-list-element:hover {
      cursor: pointer;
    }
  
    .main-page-mobile__courses-modal-your-course-flag {
      width: 90px;
      margin-bottom: 5px;
    }
  
    .main-page-mobile__courses-modal-other-course-flag {
      width: 70px;
      margin-bottom: 5px;
      filter: grayscale(60%);
    }
  
  .main-page-mobile__courses-modal-info {
    font-size: 1.4rem;
  }
  
  .main-page-mobile__courses-modal-other-courses-list-element {
    color: #000;
  }
  
  